.cco_dropdown {
    $dropdown: &;

    &_0 {
        margin-left: -7px;
        margin-right: -7px;
        position: relative;

        #{$dropdown}_toggle {
            background-color: transparent;
            cursor: pointer;
            display: flex;
            align-items: center;
            min-height: 40px;
            max-width: 100%;
            padding: 7px 35px 7px 49px;
            border: 0;
            margin-left: auto;
            position: relative;

            &_img {
                background-color: transparent;
                object-fit: cover;
                height: 42px;
                width: 42px;
                border-radius: 50%;
                position: absolute;
                top: calc(50% - 20px);
                left: 6px;
               padding: 2px;
                display: none;
            }

            &_text {
                // color: #000000;
                color: #454343;
                font-family: 'DMSans Bold';
                font-size: 14px;
                font-weight: 700;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: block;
                width: 100%;
                overflow: hidden;
            }

            &::after {
                color: #dfbd26;
                content: '\e912';
                font-family: 'Icomoon';
                font-size: 10px;
                font-style: normal;
                line-height: 1;
                speak: never;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                position: absolute;
                top: calc(50% - 4px);
                right: 7px;
            }
        }

        #{$dropdown}_content {
            background-color: #fff;
            display: none;
            width: 250px;
            border: 1px solid #e2e2ea;
            border-radius: 5px;
            box-sizing: 0 0 30px rgba(0, 0, 0, 0.1);
            overflow: hidden;
            position: absolute;
            top: calc(100% + 4px);
            right: 7px;
            z-index: 100;
        }

        &.active {
            #{$dropdown} {
                &_toggle::after {
                    transform: scale(-1);
                }

                &_content {
                    display: block;
                }
            }
        }

        @media (max-width: 980px) {
            #{$dropdown}_toggle {
                padding: 4px 7px;

                &_img {
                    position: static;
                    display: block;
                   
                }

                &_text {
                    display: none;
                }

                &::after {
                    display: none;
                }
            }
        }
    }
}

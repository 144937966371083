.custom-overlay {
    position: fixed;
    z-index: 100; 
  
    width: 100vw;
    height: 100%;
    display: block;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    pointer-events: none;
}

.bannerVisible{
    max-width: 1350px;
    padding: 80px 40px 17.5px;
    margin-left: 250px;
    margin-right: auto;

    @media (min-width: 1851px) {
        margin-left: auto;
    }
    @media (max-width: 1199px) {
        margin-left: auto;
    }
    @media (max-width: 767px) {
        padding-bottom: 15px;
        padding-left: 25px;
        padding-right: 25px;
    }
}

.cco_text {
    &_inner > *:last-child {
        padding-bottom: 0;
    }
    &_danger {
        background-color: red;
        color: #fff;
        font-size: 16px;
        padding: 15px 25px;
        border: 1px solid red;
        margin-bottom: 40px;
    }
}

.cco_row {
    &_a {
        margin-bottom: 25px;
    }

    &_b {
        margin-bottom: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &_c {
        display: flex;
        flex-wrap: wrap;
    }

    &_d {
        margin-bottom: 15px;
    }
}

.ccs_loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 40px;

    &_fieldset {
        position: absolute;
        top: 0;
        right: 40px;
        width: auto;
    }

    &_container {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
        right: 0;
        bottom: 0;
        opacity: 0.8;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    &_relative_container {
        position: relative;
    }

    &_item {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 32px;
        height: 32px;
        margin: 4px;
        border-radius: 50%;
        border: 4px solid #c67b05;
        border-color: #c67b05 transparent transparent;
        animation: ccoLoad 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    }

    &_item:nth-child(1) {
        animation-delay: -0.45s;
    }

    &_item:nth-child(2) {
        animation-delay: -0.3s;
    }

    &_item:nth-child(3) {
        animation-delay: -0.15s;
    }
}

@keyframes ccoLoad {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.cco_text {
    &_inner > *:last-child {
        padding-bottom: 0;
    }

    &_0 {
        color: #92929d;
        font-family: 'HKGrotesk Regular';
        font-size: 18px;

        strong {
            color: #171725;
            font-family: 'DMSans Bold';
            font-size: 24px;
            line-height: 1.3;
        }
    }

    &_1 {
        margin-bottom: 10px;

        strong {
            color: #000000;
            font-family: 'DMSans Medium';
            font-weight: 500;
        }

        h1 {
            font-size: 32px;

            small {
                color: #666666;
                font-family: 'DMSans Regular';
                font-weight: 400;
                font-size: 14px;
                display: inline-block;
                position: relative;
                top: -4px;
            }
        }

        .divider {
            margin-left: 20px;
        }
    }

    &_2 {
        &:nth-last-child(n + 2) {
            margin-bottom: 30px;
        }

        h2 {
            font-size: 20px;

            small {
                color: #666666;
                font-family: 'DMSans Regular';
                font-weight: 400;
                font-size: 14px;
                display: inline-block;
            }
        }
    }

    &_3 {
        font-family: 'HKGrotesk Regular';
        font-size: 14px;
        line-height: 1.3;

        h3 {
            font-family: 'DMSans Medium';
            font-size: 16px;
            font-weight: 500;
        }

        .success {
            color: #3dd598;
        }
        .error {
            color: #db3a3a;
        }
    }

    &_4 {
        background-color: #fafafb;
        font-family: 'DMSans Medium';
        font-size: 10px;
        font-weight: 500;
        line-height: 1.4;
        text-transform: uppercase;
        padding: 10px 15px;

        &:nth-child(n + 2) {
            border-top: 1px solid #e2e2ea;
        }
    }

    &_5 {
        margin-bottom: 40px;

        h1 {
            font-size: 24px;
        }
    }

    &_6 {
        font-size: 18px;
        margin-bottom: 10px;
    }

    &_7 {
        font-size: 10px;
    }

    &_8 {
        font-family: 'DMSans Medium';
        font-weight: 500;
        margin-bottom: 20px;
    }

    &_9 {
        font-size: 14px;

        h3 {
            font-size: 18px;
        }
    }

    &_10 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        h2 {
            font-size: 20px;

            small {
                color: #666666;
                font-family: 'DMSans Regular';
                font-weight: 400;
                font-size: 14px;
                display: inline-block;
            }
        }
    }

    &_11 {
        color: #171725;
        font-family: 'DMSans Bold';
        font-weight: 700;
        font-size: 18px;
        line-height: 1.3;

        strong {
            font-size: 24px;
        }

        a {
            color: #c67b05;
        }
    }

    &_12 {
        background-color: #c67b05;
        color: #fff;
        font-size: 16px;
        padding: 20px 25px;
        border: 1px solid #c67b05;
        margin-bottom: 40px;
    }

    &_13 {
        h1 {
            color: #171725;
            font-size: 32px;
        }
    }

    &_14 {
        background-color: #fff;
        padding: 25px 20px 30px;
        border-radius: 15px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    }

    &_15 {
        color: #171725;
    }

    &_myCredential {
        font-size: 12px;

        h3 {
            font-size: 15px;
        }
    }
}

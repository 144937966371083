.cco_group {
    $group: &;

    &_0 {
        display: flex;
        flex-wrap: wrap;
        margin-left: -12.5px;
        margin-right: -12.5px;

        #{$group}_item {
            width: 50%;
            padding-left: 12.5px;
            padding-right: 12.5px;

            &:nth-child(n + 3) {
                margin-top: 25px;
            }
        }

        @media (max-width: 980px) {
            margin-left: 0;
            margin-right: 0;

            #{$group}_item {
                width: 100%;
                padding-left: 0;
                padding-right: 0;

                &:nth-child(n + 2) {
                    margin-top: 25px;
                }
            }
        }
    }

    &_1 {
        display: flex;
        flex-wrap: wrap;
        margin-left: -12.5px;
        margin-right: -12.5px;

        a{
            color: #666;
        }

        #{$group}_item {
            width: 33%;
            padding-left: 12.5px;
            padding-right: 12.5px;

            &:nth-child(n + 4) {
                margin-top: 25px;
            }
        }

        @media (max-width: 980px) {
            #{$group}_item {
                width: 50%;

                &:nth-child(n + 3) {
                    margin-top: 25px;
                }
            }
        }

        @media (max-width: 767px) {
            margin-left: 0;
            margin-right: 0;

            #{$group}_item {
                width: 100%;
                padding-left: 0;
                padding-right: 0;

                &:nth-child(n + 2) {
                    margin-top: 25px;
                }
            }
        }
    }

    &_2 {
        display: flex;
        flex-wrap: wrap;
        margin-left: -12.5px;
        margin-right: -12.5px;

        #{$group}_item {
            width: 25%;
            padding-left: 12.5px;
            padding-right: 12.5px;

            &:nth-child(n + 5) {
                margin-top: 25px;
            }
        }

        @media (max-width: 980px) {
            #{$group}_item {
                width: 33%;

                &:nth-child(n + 4) {
                    margin-top: 25px;
                }
            }
        }

        @media (max-width: 767px) {
            margin-left: 0px;
            margin-right: 0px;

            #{$group}_item {
                width: 100%;
                padding-left: 0px;
                padding-right: 0px;

                &:nth-child(n + 2) {
                    margin-top: 25px;
                }
            }
        }
    }

    &_myCredential {
        display: flex;
        flex-wrap: wrap;
        margin-left: -12.5px;
        margin-right: -12.5px;

        a{
            color: #666;
        }

        #{$group}_item {
            width: 33%;
            padding-left: 12.5px;
            padding-right: 12.5px;

            &:nth-child(n + 4) {
                margin-top: 25px;
            }
        }

        @media (max-width: 980px) {
            #{$group}_item {
                width: 50%;

                &:nth-child(n + 3) {
                    margin-top: 25px;
                }
            }
        }

        @media (max-width: 767px) {
            margin-left: 0;
            margin-right: 0;

            #{$group}_item {
                width: 100%;
                padding-left: 0;
                padding-right: 0;

                &:nth-child(n + 2) {
                    margin-top: 25px;
                }
            }
        }
    }
}

[class^='cco_icon_'],
[class*=' cco_icon_'] {
    color: #666;
    font-family: 'Icomoon' !important;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: never;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cco_icon {
    &_arrow {
        &_up {
            &_1::before {
                content: '\e915';
            }
        }
        &_down {
            &_1::before {
                content: '\e916';
            }
        }
        &_right {
            &_1::before {
                content: '\e906';
            }
        }
    }

    &_avatar {
        &_1::before {
            content: '\e910';
        }
        &_2::before {
            content: '\e91a';
        }
    }

    &_badge {
        &_1 {
            &_path_0::before {
                content: '\e92d';
            }
            &_path_1::before {
                content: '\e92e';
            }
        }
    }

    &_camera {
        &_1 {
            &_path_0::before {
                content: '\e91b';
            }
            &_path_1::before {
                content: '\e91c';
                margin-left: -1em;
            }
            &_path_2::before {
                content: '\e91d';
                margin-left: -1em;
            }
        }
        &_2::before {
            content: '\e92c';
        }
    }

    &_caret {
        &_down {
            &_1::before {
                content: '\e912';
            }
            &_2::before {
                content: '\e911';
            }
        }
        &_left {
            &_1::before {
                content: '\e927';
            }
            &_2::before {
                content: '\e929';
            }
        }
        &_right {
            &_1::before {
                content: '\e900';
            }
            &_2::before {
                content: '\e928';
            }
        }
    }

    &_cart {
        &_1::before {
            content: '\e901';
        }
    }

    &_certificate {
        &_1::before {
            content: '\e90a';
        }
    }

    &_chatting {
        &_1::before {
            content: '\e907';
        }
    }

    &_check {
        &_1::before {
            content: '\e90d';
        }
        &_2::before {
            content: '\e90c';
        }
        &_3 {
            &_path_0::before {
                content: '\e91e';
            }
            &_path_1::before {
                content: '\e91f';
                margin-left: -1em;
            }
        }
        &_4::before {
            content: '\e920';
        }
    }

    &_click {
        &_1::before {
            content: '\e902';
        }
    }

    &_close {
        &_1::before {
            content: '\e919';
        }
    }

    &_crane {
        &_1::before {
            content: '\e903';
        }
    }

    &_dashboard {
        &_1::before {
            content: '\e90b';
        }
    }

    &_driver_license {
        &_1::before {
            content: '\e921';
        }
    }

    &_ellipsis {
        &_1::before {
            content: '\e905';
        }
    }

    &_gear {
        &_1::before {
            content: '\e90f';
        }
    }

    &_loader {
        &_1 {
            &_path_0::before {
                content: '\e922';
            }
            &_path_1::before {
                content: '\e923';
                margin-left: -1em;
            }
            &_path_2::before {
                content: '\e924';
                margin-left: -1em;
            }
            &_path_3::before {
                content: '\e925';
                margin-left: -1em;
            }
            &_path_4::before {
                content: '\e926';
                margin-left: -1em;
            }
        }
    }

    &_logout {
        &_1::before {
            content: '\e90e';
        }
    }

    &_loupe {
        &_1::before {
            content: '\e914';
        }
        &_2::before {
            content: '\e913';
        }
    }

    &_megaphone {
        &_1::before {
            content: '\e917';
        }
    }

    &_menu {
        &_1::before {
            content: '\e918';
        }
    }

    &_paper {
        &_1::before {
            content: '\e909';
        }
    }

    &_paper_plane {
        &_1::before {
            content: '\e908';
        }
    }

    &_plus {
        &_1::before {
            content: '\e904';
        }
    }

    &_external {
        &_1::before {
            content: '\e92a';
        }
    }

    &_file {
        &_1::before {
            content: '\e92b';
        }
    }
}



.desktop-arrow_1 {
    // width: 0;
    // height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 20px solid #c67b05; /* Adjust the color as needed */
}

// .mobile-arrow {
//     display: none; /* Hide the down arrow by default */
//     width: 0;
//     height: 0;
//     border-left: 10px solid transparent;
//     border-right: 10px solid transparent;
//     border-top: 15px solid #000; /* Adjust the color as needed */
// }

.desktop-arrow {
    display: block; /* Show the right arrow by default */
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 25px solid #c67b05; 
    margin-top: 10px;
}

.mobile-arrow {
    display: none; /* Hide the down arrow by default */
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 25px solid #c67b05; 
    bottom: 10px; 
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
}

@media (max-width: 768px) {
    /* Hide the right arrow on screens smaller than 768px */
    .desktop-arrow {
        display: none;
    }

    /* Show the down arrow on screens smaller than 768px */
    .mobile-arrow {
        display: block;
    }
}








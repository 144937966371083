.cco_btn {
    &_0 {
        text-align: right;

        a {
            background-color: #c67b05;
            color: #ffffff;
            font-family: 'DMSans Bold';
            font-weight: 700;
            line-height: 1.3;
            text-align: center;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            min-height: 40px;
            padding: 7px 16px;
            border: 2px solid #c67b05;
            border-radius: 21px;
        }
    }

    &_1 {
        margin-bottom: 20px;

        a {
            background-color: #c67b05;
            color: #ffffff;
            font-family: 'DMSans Bold';
            font-size: 18px;
            font-weight: 700;
            line-height: 1.3;
            text-align: center;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            min-height: 42px;
            width: 296px;
            max-width: 100%;
            padding: 8px 17px;
            border: 1px solid #c67b05;
            border-radius: 21px;

            &::after {
                color: #fff;
                content: '\e92a';
                font-family: 'Icomoon';
                font-size: 12px;
                font-style: normal;
                line-height: 1;
                speak: never;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                margin-left: 6px;
            }
        }
    }


    &_2 {
        padding-top: 20px;
        border-top: 1px solid #e2e2ea;
        margin-top: 20px;

        a {
            background-color: #c67b05;
            color: #ffffff;
            font-family: 'DMSans Bold';
            font-size: 18px;
            font-weight: 700;
            line-height: 1.3;
            text-align: center;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            min-height: 42px;
            min-width: 100px;
            padding: 8px 17px;
            border: 1px solid #c67b05;
            border-radius: 21px;
        }

        
    }

    &_3 {
        text-align: right;

        a {
            background-color: #c67b05;
            color: #ffffff;
            font-family: 'DMSans Bold';
            font-weight: 700;
            line-height: 1.3;
            text-align: center;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            min-height: 40px;
            padding: 7px 16px;
            border: 2px solid #c67b05;
            border-radius: 21px;
        }

        @media (max-width: 980px) {
            text-align: left;
        }
    }

    &_4 {
        margin-left: -8px;
        margin-right: -8px;

        button {
            background-color: transparent;
            color: #666666;
            cursor: pointer;
            appearance: none;
            padding: 8px;
            border: 0;

            &::before {
                content: '\e918';
                font-family: 'Icomoon';
                font-size: 24px;
                font-style: normal;
                line-height: 1;
                speak: never;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                display: block;
            }

            span {
                display: none;
            }
        }
    }

    &_5 {
        a {
            background-color: #c67b05;
            color: #fff !important;
            font-family: 'DMSans Bold';
            font-weight: 700;
            line-height: 1.3;
            text-align: center;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            min-height: 40px;
            padding: 7px 16px;
            border: 2px solid #c67b05;
            border-radius: 21px;
        }
        .uploadbtn {
            background-color: #c67b05;
            color: #ffffff;
            font-family: 'DMSans Bold';
            font-size: 18px;
            font-weight: 700;
            line-height: 1.3;
            text-align: center;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            min-height: 42px;
            min-width: 100px;
            padding: 8px 17px;
            border: 1px solid #c67b05;
            border-radius: 21px;
            cursor: pointer;
        }
    }

    &_6 {
        padding-top: 20px;
        border-top: 1px solid #e2e2ea;
        margin-top: 20px;

        a {
            background-color: #c67b05;
            color: #ffffff;
            font-family: 'DMSans Bold';
            font-size: 18px;
            font-weight: 700;
            line-height: 1.3;
            text-align: center;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            min-height: 42px;
            min-width: 100px;
            padding: 8px 29px;
            border: 1px solid #c67b05;
            border-radius: 21px;
        }

        @media (max-width: 1199px) {
            a {
                font-size: 16px;
            }
        }

        @media (max-width: 479px) {
            a {
                width: 100%;
            }
        }
    }

    &_7 {
        margin-bottom: 20px;

        a {
            background-color: #c67b05;
            color: #ffffff;
            font-family: 'DMSans Bold';
            font-size: 18px;
            font-weight: 700;
            line-height: 1.3;
            text-align: center;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            min-height: 42px;
            width: 296px;
            max-width: 100%;
            padding: 8px 17px;
            border: 1px solid #c67b05;
            border-radius: 21px;

            &::after {
                color: #fff;
                content: '\e05b';
                
                font-family: 'Icomoon';
                font-size: 12px;
                font-style: normal;
                line-height: 1;
                speak: never;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                margin-left: 6px;
            }
        }
    }

    &_8 {
        padding-top: 10px;
        border-top: 1px solid #e2e2ea;
        margin-top: 10px;

        a {
            background-color: #c67b05;
            color: #ffffff;
            font-family: 'DMSans Bold';
            font-size: 14px;
            font-weight: 600;
            line-height: 1;
            text-align: center;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            min-height: 35px;
            min-width: 100px;
            padding: 8px;
            border: 1px solid #c67b05;
            // border-radius: 21px;
        }
        button{
            background-color: #c67b05;
            color: #ffffff;
            font-family: 'DMSans Bold';
            font-size: 14px;
            font-weight: 600;
            line-height: 1;
            text-align: center;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            min-height: 35px;
            min-width: 100px;
            padding: 8px 8px;
            margin: 5px;
            border: 1px solid #c67b05;
            cursor: pointer;
        }

        
    }

    &_noBorder {
        a {
            background-color: #c67b05;
            color: #ffffff;
            font-family: 'DMSans Bold';
            font-size: 14px;
            font-weight: 600;
            line-height: 1;
            text-align: center;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            min-height: 35px;
            min-width: 100px;
            padding: 8px;
            border: 1px solid #c67b05;
            // border-radius: 21px;
        }
        button{
            background-color: #c67b05;
            color: #ffffff;
            font-family: 'DMSans Bold';
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            min-height: 35px;
            min-width: 100px;
            padding: 8px 8px;
            border: 1px solid #c67b05;
            cursor: pointer;
        }

        
    }

    &_mybtn {
        margin-bottom: 20px;

        button {
            background-color: #c67b05;
            color: #ffffff;
            font-family: 'DMSans Bold';
            font-size: 18px;
            font-weight: 700;
            line-height: 1.3;
            text-align: center;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            min-height: 42px;
            width: 296px;
            max-width: 100%;
            padding: 8px 17px;
            border: 1px solid #c67b05;
            border-radius: 21px;
            cursor: pointer;
        }
    }
}

@font-face {
    font-family: 'Icomoon';
    // src: url('../../../src/assets/fonts/Icomoon/Icomoon.eot?knrjde');
    // src: url('../../../src/assets/Icomoon/Icomoon.woff?knrjde') format('woff')
     src:  url('../../../src/assets/fonts/Icomoon/Icomoon.woff2?knrjde') format('woff2');
       
    font-weight: 400;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: 'DMSans Bold';
    // src: url('../../../src/assets/fonts/DMSans/DMSans-Bold.eot');
    src: url('../../../src/assets/fonts/DMSans/DMSans-Bold.woff') format('woff'),
        url('../../../src/assets/fonts/DMSans/DMSans-Bold.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'DMSans Medium';
    // src: url('../../../src/assets/fonts/DMSans/DMSans-Medium.eot');
    src: url('../../../src/assets/fonts/DMSans/DMSans-Medium.woff') format('woff'),
        url('../../../src/assets/fonts/DMSans/DMSans-Medium.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'DMSans Regular';
    // src: url('../../../src/assets/fonts/DMSans/DMSans-Regular.eot');
    src: url('../../../src/assets/fonts/DMSans/DMSans-Regular.woff') format('woff'),
        url('../../../src/assets/fonts/DMSans/DMSans-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'HKGrotesk Regular';
    // src: url('../../../src/assets/fonts/HKGrotesk/HKGrotesk-Regular.eot');
    src: url('../../../src/assets/fonts/HKGrotesk/HKGrotesk-Regular.woff') format('woff'),
        url('../../../src/assets/fonts/HKGrotesk/HKGrotesk-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HKGrotesk Medium';
    // src: url('../../../src/assets/fonts/HKGrotesk/HKGrotesk-Medium.eot');
    src: url('../../../src/assets/fonts/HKGrotesk/HKGrotesk-Medium.woff2') format('woff2'),
        url('../../../src/assets/fonts/HKGrotesk/HKGrotesk-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.cco_collapse {
    $collapse: &;
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);

    &:nth-last-child(n + 2) {
        margin-bottom: 25px;
    }

    &_header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 24px;
        padding-right: 43px;
        position: relative;
    }

    &_toggle {
        background-color: transparent;
        color: #c67b05;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        width: auto;
        min-height: 0;
        padding: 7px;
        border: 0;
        border-radius: 0;
        position: absolute;
        top: -2px;
        right: -5px;

        &::before {
            content: '\e911';
            font-family: 'Icomoon';
            font-style: normal;
            speak: never;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            display: block;
        }

        &_text {
            display: none;
        }
    }

    &_body {
        display: none;

        &_inner {
            padding-top: 20px;
            border-top: 1px solid #e4e4e2;
            margin-top: 20px;
        }
    }

    &.active {
        #{$collapse}_toggle::before {
            transform: scale(-1);
        }

        #{$collapse}_body {
            display: block;
        }
    }
}

.cco_sidebar {
    background-color: #ffffff;
    height: 100vh;
    width: 250px;
    padding-top: 70px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    &_inner {
        padding-top: 40px;
        padding-bottom: 40px;
        overflow-y: auto;
    }

    &_overlay {
        background-color: #171725;
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 150ms ease-in-out;
        z-index: 100;
    }

    @media (max-width: 1199px) {
        width: 325px;
        max-width: 90%;
        box-shadow: 10px 0 30px rgba(0, 0, 0, 0.1);
        transform: translateX(-100%);
        transition: all 300ms ease-in-out;

        &_active {
            transform: translateX(0);
        }

        &_overlay_active {
            opacity: 0.3;
            visibility: visible;
        }
    }

    @media (max-width: 767px) {
        background-color: #fafafb;
    }
}

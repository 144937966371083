.cco_fieldset {
    $fieldset: &;

    &_0 {
        color: #000;
        font-family: 'DMSans Regular';
        font-size: 14px;
        font-weight: 400;
        line-height: 1.3;
        display: flex;
        align-items: center;

        &:nth-child(n + 2) {
            margin-top: 12px;
        }

        #{$fieldset}_item {
            &:nth-child(n + 2) {
                padding-left: 20px;
            }

            &_large {
                flex-grow: 1;
            }
        }
    }
}

.cco_form_field_body_p {
    display: flex; /* Use flexbox to create a row */
    align-items: center; /* Align items vertically in the center */
    justify-content: flex-start; /* Align items horizontally to the left */
  }
  
  /* Add spacing between the icons */
  .cco_form_field_label_small {
    margin-right: 10px; /* Adjust as needed */
  }
  
  /* Hide the newline character inside the label elements */
  .cco_form_field_label_small > span {
    white-space: nowrap;
  }


  .cropper-face{
     opacity: 0.8 !important;
    

  }

  .cropper-face{
    background: url('../../assets/images/mask_uk.png') no-repeat center !important;
  }



  .no-mask .cropper-face {
    background: none !important; /* or background: transparent !important; */
  }

  .cropper-view-box{
    opacity: 0.8 !important;
  }


  .webCam video{
    border-radius: 20px;
    border: 2px dashed #c67b05;
    padding: 2px;
  }

  .cropper-drag-box{
    border-radius: 10px;
  }
.cco_col {
    &_0 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 30%;
        padding-right: 20px;
    }

    &_1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 70%;
        padding-left: 20px;
    }

    &_2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 15px;
    }

    &_3 {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &_4 {
        padding-right: 15px;

        @media (max-width: 767px) {
            padding-right: 0;
            margin-bottom: 20px;
        }
    }

    &_5 {
        padding-left: 15px;

        @media (max-width: 767px) {
            padding-left: 0;
        }
    }

    &_6 {
        width: 100%;

        &:nth-last-child(n + 2) {
            width: 50%;
            padding-right: 12.5px;
        }

        @media (max-width: 980px) {
            &:nth-last-child(n + 2) {
                width: 100%;
                padding-right: 0;
                margin-bottom: 25px;
            }
        }
    }

    &_7 {
        width: 50%;
        padding-left: 12.5px;

        @media (max-width: 980px) {
            width: 100%;
            padding-left: 0;
        }
    }

    &_8 {
        width: calc(33.33% - 16.66px);

        &:nth-last-child(n + 2) {
            margin-right: 25px;
        }

        @media (max-width: 980px) {
            width: calc(50% - 12.5px);

            &:nth-last-child(n + 2) {
                margin-bottom: 25px;
            }
            &:nth-child(2n) {
                margin-right: 0;
            }
        }

        @media (max-width: 767px) {
            width: 100%;

            &:nth-last-child(n + 2) {
                margin-bottom: 25px;
                margin-right: 0;
            }
        }
    }

    &_9 {
        display: none;
        flex-direction: column;
        justify-content: center;
        max-width: 30%;
        padding-right: 20px;

        @media (max-width: 1199px) {
            display: flex;
        }
    }

    &_10 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 30%;
        padding-right: 20px;

        @media (max-width: 1199px) {
            padding-left: 20px;
        }
    }

    &_11 {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 70%;
        padding-left: 20px;
    }

    &_12 {
        width: 100%;
    }
}

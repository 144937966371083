.cco_menu {
    $menu: &;

    &_0 {
        display: flex;
        margin-left: -9px;
        margin-right: -9px;

        #{$menu}_item {
            padding-left: 9px;
            padding-right: 9px;
            margin-bottom: 15px;

            a {
                background-color: #ffffff;
                color: #c67b05;
                font-family: 'DMSans Bold';
                font-size: 18px;
                font-weight: 700;
                line-height: 1.3;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                min-height: 42px;
                padding: 8px 29px;
                border: 1px solid #c67b05;
                border-radius: 21px;
            }

            &_primary {
                flex-grow: 1;

                a {
                    background-color: #c67b05;
                    color: #ffffff;
                }
            }
        }

        @media (max-width: 1199px) {
            #{$menu}_item a {
                font-size: 16px;
            }
        }

        @media (max-width: 980px) {
            flex-wrap: wrap;

            #{$menu}_item {
                width: 50%;
            }
        }

        @media (max-width: 767px) {
            margin-left: 0;
            margin-right: 0;

            #{$menu}_item {
                width: 100%;
                padding-left: 0;
                padding-right: 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &_1 {
        #{$menu}_item {
            a {
                color: #000000;
                font-size: 13px;
                line-height: 1.3;
                display: block;
                padding: 10px 15px 10px 37px;
                border-top: 1px solid #e2e2ea;
                position: relative;

                &::before {
                    color: #666666;
                    content: '\e906';
                    font-family: 'Icomoon';
                    font-size: 14px;
                    font-style: normal;
                    line-height: 1;
                    speak: never;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    position: absolute;
                    top: calc(50% - 7px);
                    left: 15px;
                }

                &:hover {
                    background-color: #fefcf4;
                }
            }

            &#{$menu}_item_icon {
                &_gear a::before {
                    content: '\e90f';
                }
                &_logout a::before {
                    content: '\e90e';
                }
                &_avatar a::before {
                    content: '\e910';
                }
            }
        }

        &:first-child #{$menu}_item:first-child a {
            border-top: 0;
        }
    }

    &_2 {
        display: flex;
        flex-wrap: wrap;
        margin-top: 5px;

        #{$menu}_item {
            margin-bottom: 5px;

            &:nth-last-child(n + 2) {
                margin-right: 10px;
            }

            a {
                background-color: #c67b05;
                color: #ffffff;
                font-size: 14px;
                font-weight: 700;
                line-height: 1.3;
                text-align: center;
                white-space: nowrap;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                min-height: 26px;
                min-width: 70px;
                padding: 2px 6px;
                border: 1px solid #c67b05;
                border-radius: 5px;
            }
        }
    }

    &_3 {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -15px;
        margin-left: -9px;
        margin-right: -9px;

        #{$menu}_item {
            padding-left: 9px;
            padding-right: 9px;
            margin-bottom: 15px;

            a {
                background-color: #c67b05;
                color: #ffffff;
                font-family: 'DMSans Bold';
                font-size: 18px;
                font-weight: 700;
                line-height: 1.3;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                min-height: 42px;
                min-width: 166px;
                padding: 8px 17px;
                border: 1px solid #c67b05;
                border-radius: 21px;
            }

            &:first-child a {
                background-color: #fff;
                color: #c67b05;
            }
        }

        @media (max-width: 1199px) {
            #{$menu}_item a {
                font-size: 16px;
            }
        }
        @media (max-width: 767px) {
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 0;

            #{$menu}_item {
                width: 100%;
                padding-left: 0;
                padding-right: 0;

                a {
                    min-width: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &_4 {
        display: flex;
        padding: 17px 11px;
        border: 1px dashed #e2e2ea;
        border-radius: 20px;

        #{$menu}_item {
            flex-grow: 1;
            padding-left: 9px;
            padding-right: 9px;

            a {
                color: #666;
                line-height: 1.3;
                display: inline-flex;
                align-items: center;
                min-height: 38px;
                padding: 3px 20px;
            }

            &#{$menu}_item_icon {
                a {
                    position: relative;

                    &::before {
                        background-color: #fff;
                        color: #c67b05;
                        font-family: 'Icomoon';
                        font-size: 15px;
                        font-style: normal;
                        line-height: 1;
                        speak: never;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        padding: 11.5px;
                        border-radius: 50%;
                        position: absolute;
                        top: calc(50% - 19px);
                    }
                }

                &_plus a::before {
                    content: '\e904';
                }
                &_close a::before {
                    content: '\e919';
                }
                &_caret_left a::before {
                    content: '\e929';
                }
                &_caret_right a::before {
                    content: '\e928';
                }
                &_checkmark a::before {
                    content: '\e920';
                }
            }

            &#{$menu}_item_icon {
                &:nth-last-child(n + 2),
                &:first-child {
                    a {
                        padding-left: 46px;

                        &::before {
                            left: 0;
                        }
                    }
                }
                &:nth-child(n + 2) {
                    text-align: center;
                }
                &:not(:first-child):last-child {
                    text-align: right;

                    a {
                        padding-right: 46px;

                        &::before {
                            right: 0;
                        }
                    }
                }
            }

            &#{$menu}_item_btn {
                a {
                    background-color: #c67b05;
                    color: #ffffff;
                    font-family: 'DMSans Bold';
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 1.3;
                    text-align: center;
                    display: inline-flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 296px;
                    max-width: 100%;
                    min-height: 42px;
                    padding: 7px 16px;
                    border: 2px solid #c67b05;
                    border-radius: 21px;
                }

                &:last-child {
                    text-align: right;
                }

                &_disabled {
                    a {
                        cursor: not-allowed;
                        opacity: 0.5;
                    }
                }
            }
        }

        @media (max-width: 1199px) {
            #{$menu}_item#{$menu}_item_btn a {
                font-size: 16px;
            }
        }

        @media (max-width: 767px) {
            flex-wrap: wrap;
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 0;

            #{$menu}_item {
                width: 100%;
                padding-left: 0;
                padding-right: 0;

                &:nth-last-child(n + 2) {
                    margin-bottom: 15px;
                }

                a {
                    font-size: 14px;
                }

                &#{$menu}_item_icon {
                    a::before {
                        box-shadow: 0 10px 30px rgb(0 0 0 / 5%);
                    }

                    &:nth-child(n + 2) {
                        text-align: left;
                    }
                    &:not(:first-child):last-child {
                        text-align: left;

                        a {
                            padding-left: 46px;
                            padding-right: 20px;

                            &::before {
                                left: 0;
                                right: unset;
                            }
                        }
                    }
                }

                &#{$menu}_item_btn a {
                    width: 100%;
                }
            }
        }
    }

    &_5 {
        #{$menu}_item {
            &:nth-last-child(n + 2) {
                margin-bottom: 20px;
            }

            a {
                color: #666666;
                line-height: 1.3;
                display: flex;
                align-items: center;
                min-height: 32px;
                padding: 5px 15px 5px 64px;
                position: relative;

                &::before {
                    content: '\e906';
                    font-family: 'Icomoon';
                    font-size: 20px;
                    font-style: normal;
                    line-height: 1;
                    speak: never;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    position: absolute;
                    top: calc(50% - 10px);
                    left: 22px;
                }
                &::after {
                    background-color: transparent;
                    content: '';
                    height: 32px;
                    width: 3px;
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                    position: absolute;
                    top: calc(50% - 16px);
                    left: 0;
                }

                &:hover {
                    color: #c67b05;
                }
            }

            &#{$menu}_item_active a {
                color: #c67b05;
                font-family: 'DMSans Bold';
                font-weight: 700;

                &::after {
                    background-color: #c67b05;
                }
            }

            &#{$menu}_item_icon {
                &_dashboard a::before {
                    content: '\e90b';
                }
                &_certificate a::before {
                    content: '\e90a';
                }
                &_paper a::before {
                    content: '\e909';
                }
                &_paper_plane a::before {
                    content: '\e908';
                }
                &_chatting a::before {
                    content: '\e907';
                }
            }
        }

        &:first-child #{$menu}_item:first-child a {
            border-top: 0;
        }
    }

    &_6 {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -15px;
        margin-left: -9px;
        margin-right: -9px;

        #{$menu}_item {
            width: 50%;
            padding-left: 9px;
            padding-right: 9px;
            margin-bottom: 15px;

            &:first-child:last-child {
                width: 100%;
            }

            a {
                background-color: #c67b05;
                color: #ffffff;
                font-family: 'DMSans Bold';
                font-size: 18px;
                font-weight: 700;
                line-height: 1.3;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                min-height: 42px;
                padding: 8px 17px;
                border: 1px solid #c67b05;
                border-radius: 21px;
            }

            &:not(:last-child):nth-child(2n + 1) a {
                background-color: #fff;
                color: #c67b05;
            }
        }

        @media (max-width: 1199px) {
            #{$menu}_item a {
                font-size: 16px;
            }
        }
        @media (max-width: 479px) {
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 0;

            #{$menu}_item {
                width: 100%;
                padding-left: 0;
                padding-right: 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &_7 {
        display: flex;
        margin-left: -9px;
        margin-right: -9px;

        #{$menu}_item {
            padding-left: 9px;
            padding-right: 9px;

            a {
                background-color: #fff;
                color: #c67b05;
                font-family: 'DMSans Bold';
                font-size: 18px;
                font-weight: 700;
                line-height: 1.3;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 296px;
                max-width: 100%;
                min-height: 42px;
                padding: 7px 16px;
                border: 2px solid #c67b05;
                border-radius: 21px;
            }
        }

        @media (max-width: 1199px) {
            #{$menu}_item a {
                font-size: 16px;
            }
        }

        @media (max-width: 767px) {
            flex-wrap: wrap;
            margin-left: 0;
            margin-right: 0;

            #{$menu}_item {
                width: 100%;
                padding-left: 0;
                padding-right: 0;

                &:nth-last-child(n + 2) {
                    margin-bottom: 15px;
                }

                a {
                    width: 100%;
                }
            }
        }
    }
}

.cco_header {
    $header: &;
    width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 30px;
    padding-bottom: 25px;
    box-shadow: 0 10px 30px rgb(0 0 0 / 5%);
    z-index: 100;

    &_container {
        display: flex;
        align-items: center;
        width: 80%;
        max-width: 1080px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }

    &_logo {
        img {
            max-width: 65px;
            max-height: 65px;
            object-fit: contain;
            display: block;

            @media (max-width: 980px) {
                max-width: 60px;
                max-height: 60px;
            }

            @media (max-width: 767px) {
                max-width: 55px;
                max-height: 55px;
            }
        }
    }

    &_nav {
        flex-grow: 1;
        padding-left: 40px;

        &_desktop {
            display: flex;

            @media (max-width: 980px) {
                display: none;
            }
        }

        &_mobile {
            @media (min-width: 981px) {
                display: none;
            }

            &_open {
                #{$header}_list_3 {
                    display: block;
                }
            }
        }
    }

    &_list {
        &_1 {
            display: flex;
            flex-wrap: wrap;
            flex-grow: 1;
            margin-left: -10px;
            margin-right: -10px;
            list-style-type: none;
            &_item {
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 7.25px;
                padding-bottom: 7.25px;

                a {
                    color: #666;
                    font-family: 'DMSans Medium';
                    font-size: 18px;
                    line-height: 1.3;
                    display: block;
                    padding: 5px;
                }
            }
        }

        &_2 {
            display: flex;
            margin-left: -15px;
            margin-right: -15px;
            padding-left: 20px;
            list-style-type: none;
            &_item {
                padding-left: 15px;
                padding-right: 15px;

                a {
                    border-radius: 24px;
                    font-family: 'DMSans Bold';
                    font-size: 18px;
                    display: block;
                    min-width: 170px;
                    padding: 8.5px 30px;
                    text-align: center;
                }

                &_register {
                    a {
                        background-color: #c67b05;
                        border: 2px solid #c67b05;
                        color: #fff;
                    }
                }

                &_sign {
                    a {
                        background-color: #fff;
                        border: 2px solid #c67b05;
                        color: #c67b05;
                    }
                }
            }
        }

        &_3 {
            position: absolute;
            top: calc(100% + 25px);
            left: 0;
            width: 100%;
            background-color: #fff;
            border-top: 3px solid #c67b05;
            padding: 4% 5% 5%;
            display: none;
            box-shadow: 0 10px 30px rgb(0 0 0 / 5%);

            &_item {
                &:nth-last-child(n + 2) {
                    border-bottom: 1px solid rgba(198, 123, 5, 0.1);
                }

                a {
                    color: #c67b05;
                    padding: 8px 30px;
                    display: inline-block;
                }

                &_register,
                &_sign {
                    padding-top: 5px;
                    padding-bottom: 5px;

                    a {
                        border: 1px solid #c67b05;
                        border-radius: 22px;
                        font-family: 'DMSans Bold';
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }
                }
            }

            @media (max-width: 767px) {
                padding: 5% 5% 7%;
            }

            @media (max-width: 479px) {
                padding: 5% 5% 10%;
            }
        }

        &_4 {
            display: flex;
            flex-wrap: wrap;
            margin-left: -10px;
            margin-right: -10px;
            padding-top: 15px;

            &_item {
                padding: 5px 10px;

                a {
                    border-radius: 24px;
                    font-family: 'DMSans Bold';
                    font-size: 18px;
                    display: block;
                    min-width: 170px;
                    padding: 6px 30px;
                    text-align: center;
                }

                &_register {
                    a {
                        background-color: #c67b05;
                        border: 2px solid #c67b05;
                        color: #fff;
                    }
                }

                &_sign {
                    a {
                        background-color: #fff;
                        border: 2px solid #c67b05;
                        color: #c67b05;
                    }
                }
            }

            @media (max-width: 479px) {
                &_item {
                    width: 100%;

                    a {
                        width: 100%;
                    }
                }
            }
        }
    }

    &_toggle {
        background-color: transparent;
        border: 0;
        cursor: pointer;
        display: block;
        margin-left: auto;
        padding: 0;
        transform: rotate(180deg);

        &_icon {
            display: block;

            &::before {
                font-family: 'Icomoon' !important;
                font-style: normal;
                font-variant: normal;
                font-weight: normal;
                line-height: 1;
                speak: never;
                text-transform: none;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: '\e918';
                color: #c67b05;
                display: block;
                font-size: 22px;
            }
        }
    }

    @media (max-width: 767px) {
        &_toggle_icon::before {
            font-size: 20px;
        }
    }
}

.page-template-home .cco_header {
    box-shadow: none;
    max-width: 1360px;
    padding-top: 50px;
    padding-left: 40px;
    padding-right: 40px;

    &_container {
        width: 100%;
        max-width: 100%;
    }

    &_logo {
        img {
            content: url('../../../src/assets/images/Logo_CCO_1.png');
            max-width: 80px;
            max-height: 80px;
        }
    }

    &_list {
        &_1 {
            &_item {
                a {
                    color: #fff;
                }
            }
        }

        &_2 {
            &_item_sign {
                a {
                    border-color: #fff;
                }
            }
        }

        &_3 {
            box-shadow: none;
        }
    }

    &_toggle {
        &_icon {
            &::before {
                color: #fff;
            }
        }
    }

    @media (max-width: 980px) {
        padding-top: 40px;

        &_logo img {
            max-width: 70px;
            max-height: 70px;
        }

        &_list_3 {
            top: calc(100% + 15px);
        }
    }

    @media (max-width: 767px) {
        padding-top: 30px;

        &_logo img {
            max-width: 65px;
            max-height: 65px;
        }

        @media (max-width: 767px) {
            &_toggle_icon::before {
                font-size: 22px;
            }
        }
    }
}
